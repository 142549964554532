import $ from "jquery";

$(document).ready(() => {
    window.setTimeout(() => {
        const consentCookieValue = getCookie(".AspNet.Consent");
        if (consentCookieValue !== "yes") {

            // Show consent form
            const $cookieConsentForm = $("#cookie-consent");
            $cookieConsentForm.fadeIn();

            // Accept cookie click event
            const $cookieConsentButton = $("#cookie-consent button[data-cookie-string]");
            $cookieConsentButton.click(event => {
                event.preventDefault();

                // Set consent cookie
                document.cookie = $cookieConsentButton.data("cookieString");

                // Hide consent form
                $cookieConsentForm.fadeOut();
            });
        }
    }, 5000);
});

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(";").shift();
    }
}