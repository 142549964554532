import $ from "jquery";
import "selectedIcons";
import "bootstrap/js/dist/collapse";
import "components/disclaimers";

$(document).ready(() => {
    $(".feedback").on("click", sendLinkByMail);
});

function sendLinkByMail(e) {
    const id = $(e.target).data("cnum");
    const subject = "Feeback on InfoEnvoy";
    const body = `${window.location.href}?id=${id}`;
    let uri = "mailto:valuewestinc@gmail.com?subject=";
    uri += encodeURIComponent(subject);
    uri += "&body=";
    uri += encodeURIComponent(body);
    window.open(uri);
}