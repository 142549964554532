import $ from "jquery";

$(() => {
    const data = $("#grid").data("items");
    const exportName = $("#grid").data("export-name");
    
    if (data && data.length) {
        const cols = Object.keys(data[0]);
        const columns = [];
        for (const key of Object.keys(cols)) {
            columns.push({ field: cols[key] });
        }

        const canExport = $("#grid").data("can-export") === "True" ? true : false;
        let settings = {
            columns: columns,
            dataSource: {
                data: data
            },
            pageable: {
                pageSize: 50
            },

            noRecords: true,
            messages: {
                noRecords: "There is no data on current page"
            },
            sortable: true,
            reorderable: true,
            scrollable: true,
            filterable: true,
            autoFitColumn: true,
            autosize: true
        };

        if (canExport) {
            settings = {
                ...settings,
                toolbar: [
                    { name: "pdf" },
                    { name: "excel", text: "Export to Excel" }
                ],
                excel: {
                    allPages: true,
                    fileName: `${exportName}.xlsx`
                },
                pdf: {
                    allPages: true,
                    avoidLinks: true,
                    paperSize: "Letter",
                    repeatHeaders: true,
                    landscape: true,
                    scale: 0.5,
                    margin: { top: "1in", bottom: "1in", right: "1in", left: "1in" },
                    fileName: `${exportName}.pdf`
                }
            };
        }
        $("#grid").kendoGrid(settings);
    } else {

        $("#grid").kendoGrid({
            columns: [
                { field: "" }
            ],
            pageable: true,
            noRecords: {
                template: function () {
                    return "";
                }
            },
            dataSource: {
                data: [],
                page: 2,
                pageSize: 10
            }
        });       
        $(".k-pager-info").html("");
        $(".k-pager-info").html("No records matched your search");
    }
});

