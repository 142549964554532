import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { faPhone as fasPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faFax as fasFax } from "@fortawesome/free-solid-svg-icons/faFax";
import { faEnvelope as fasEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faUser as fasUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faMapMarkerAlt as fasMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faSearch as fasSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faPlus as fasPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faMinus as fasMinus } from "@fortawesome/free-solid-svg-icons/faMinus";
import { faChevronLeft as fasChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faInfoCircle as fasInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";

library.add(
    fasPhone,
    fasFax,
    fasEnvelope,
    fasUser,
    fasMapMarkerAlt,
    fasSearch,
    fasPlus,
    fasMinus,
    fasChevronLeft,
    fasInfoCircle
);

dom.watch();